import React from "react"
import styled from "@emotion/styled"
import Helmet from "react-helmet"

const BreadcrumbsContainer = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-position:inside;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => (props.invert ? "#fff" : "#6c757d")};

  .breadcrumb-item + .breadcrumb-item::before {
    display: inline;
    padding-right: 5px;
    padding-left: 5px;
    color: ${props => (props.invert ? "#fff" : "#6c757d")};
    content: "/";

    @media (min-width: 768px) {
      padding-right: 8px;
      padding-left: 8px;
      text-overflow: none;
    }
  }
`

const BreadcrumbItem = styled.li`
  display: inline-flex;
  font-size: 12px;
  align-items: center;

  span {
    color: ${props => (props.invert ? "#fff" : "#999")};
  }
`

const Link = styled.a`
  color: ${props => (props.current ? props.theme.accentColor : "#fff")};
`

class Breadcrumbs extends React.Component {
  schemaOrgJSONLD(breadcrumbs) {
    let schemaItems = []

    breadcrumbs.forEach(function (breadcrumb, i) {
      schemaItems.push({
        "@type": "ListItem",
        position: i + 1,
        item: {
          "@id": breadcrumb.url,
          name: breadcrumb.title,
        },
      })
    })

    let schema = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: schemaItems,
    }

    return schema
  }

  render() {
    const { pageContext, invert } = this.props
    const { modules } = pageContext
    const { breadcrumbs = [] } = modules

    return (
      <React.Fragment>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(this.schemaOrgJSONLD(breadcrumbs))}
          </script>
        </Helmet>
        <BreadcrumbsContainer invert={invert ? 1 : undefined} className="py-2">
          {breadcrumbs.map((breadcrumb, i) => (
            <BreadcrumbItem
              className="breadcrumb-item"
              invert={invert ? 1 : undefined}
              key={i}
            >

            {i === 0 ?(
              <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="mr-1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-98.000000, -120.000000)">
                    <rect fill="url(#radialGradient-1)" x="0" y="0" width="1440" height="470"></rect>
                    <g transform="translate(98.000000, 118.000000)">
                      <g fill="#FFFFFF" opacity="0.5">
                        <g transform="translate(0.000000, 2.000000)">
                          <path d="M6.5,9.35458333 L9.30041667,11.0445833 C9.50625,11.1691667 9.76083333,10.985 9.70666667,10.7520833 L8.96458333,7.56708333 L11.4345833,5.4275 C11.6133333,5.27041667 11.52125,4.9725 11.2775,4.95083333 L8.02208333,4.67458333 L6.74916667,1.67375 C6.65708333,1.45166667 6.34291667,1.45166667 6.25083333,1.67375 L4.97791667,4.67458333 L1.7225,4.95083333 C1.48416667,4.9725 1.38666667,5.27041667 1.57083333,5.4275 L4.04083333,7.56708333 L3.29875,10.7520833 C3.24458333,10.985 3.49916667,11.1691667 3.705,11.0445833 L6.5,9.35458333 Z"></path>
                        </g>
                      </g>
                      <g opacity="0.300000012" transform="translate(0.000000, 2.000000)"></g>
                    </g>
                  </g>
                </g>
              </svg>
            ):null}

              <Link href={breadcrumb.url} current={i === breadcrumbs?.length - 1 ? 1:undefined}>{breadcrumb.title}</Link>

            </BreadcrumbItem>
          ))}
        </BreadcrumbsContainer>
      </React.Fragment>
    )
  }
}

export default Breadcrumbs
