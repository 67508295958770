import React, { useState, useContext } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react"
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Grid, Row, Col } from "react-flexbox-grid";
import { getAffiliateLinkWithMetadata, rankProviders, scoreColors } from "../../utils/index";
import cloudinary from "../../utils/cloudinary";
import GeoContext from "../context/geoContext";
import { CtaButton } from "./styledComponents";
import FlagCheckmark from "./flagCheckmark.js";

const ProviderContainer = styled.div`
  ${props =>
    props.minimal && props.borders &&
    css`
      border-left: 1px solid #dfdfdf;
      border-right: 1px solid #dfdfdf;
  `}

  ${props =>
    props.minimal &&
    css`
      padding-bottom: 0 !important;
    `}
`

const Provider = styled.div`
  position: relative;
  background: #fff;
  border-radius: 6px;
  margin-bottom: ${props => props.minimal ? "0" : "12px"};
  overflow: hidden;
  box-shadow: ${props => props.minimal ? "none" : "rgba(72, 72, 72, 0.08) 0px 0px 16px 3px"};
  color: #333;

  .content {
    font-size: 17px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: ${(props) => (props.rtl ? "right" : "left")};
    justify-content: center;
    padding-right: 10px;
  }

  .placeholderHidden {
    opacity: ${(props) => (props.placeholder ? "0" : "1")};
    pointer-events: ${props => props.placeholder ? "none" : "auto"};
  }

  .providerLink {
    color: rgb(0, 0, 255);
  }

  .bonus-heading {
    font-size: 13px;
    line-height: 12px;
  }

  .bonus {
    display: block;
    font-size: 16px;
    color: ${(props) => props.theme.accentColor};
    font-weight: 600;
  }

  .logoContainer {
    /* background: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : "#fff"}; */
    padding: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    align-self: auto;
    border-radius: 50%;

    @media only screen and (min-width: 768px) {
      height: 120px;
      height: auto;
    }
  }

  .usp {
    display: block;
    font-size: 16px;
  }

  .totalScore {
    display: inline-block;
    padding: 0px;
    background: ${(props) => props.color};
    color: #fff;
    border-radius: 5px;
  }

  .providerName {
    display: block;
    text-align: ${(props) => (props.rtl ? "right" : "left")};
    font-weight: 600;
    font-size: 16px;
  }

  .score {
    display: inline-block;
    font-size: 40px;
    font-weight: 900;
    line-height: 20px;
    color: #fff;
    padding: 10px;
  }
`;

const Logo = styled.img`
  object-fit: contain;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : "#fff"};

  @media only screen and (min-width: 992px) {
    width: 80px;
    height: 80px;
  }

  @media only screen and (min-width: 1400px) {
    width: 90px;
    height: 90px;
  }
`;

const placeholder = {
  "placeholder": true,
  "name": "-",
  "bonus": "-",
  "bonus-text": "-",
  "pros": "-",
  "about-provider": "&nbsp;",
  "tc-text": "-",
  "properties": {
      "logotype": {
          "resolved": [
              {
                  "url": "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
              }
          ]
      },
      "affiliate-link": {
          "data": {
              "value": "#"
          }
      },
  },
  "path": "#",
}

export default (props) => {
  const [loaded, setLoaded] = useState(false);
  let {
    providers = [],
    count,
    scrollPosition,
    pageContext,
    dynamic,
    skip = 0,
    horizontal,
    rotate = true,
    outboundClickPrefix = "top_",
    minimal = false,
    resourceId
  } = props;
  const { site, themeSettings = {} } = pageContext;
  const { disableListings, disableBonuses = false } = themeSettings;
  const geoContext = useContext(GeoContext);

  if (disableListings) return null

  function sliceProviders({ providers, count, skip, rotate }) {
    if (!providers?.length) return [];

    if (typeof window !== "undefined" && rotate) {
      try {
        let pageViewCount = parseInt(
          window.localStorage.getItem("pageviewCount")
        );
        if (pageViewCount < providers.length - 3) {
          skip = pageViewCount;
        }
      } catch {}
    }

    providers = providers?.slice(skip, providers.length);

    return providers?.slice(0, count ? count : providers.length) || [];
  }

  let outputProviders = sliceProviders({
    count: count,
    skip: skip,
    rotate,
    providers: rankProviders({
      providers: dynamic ? geoContext?.providers : providers,
      geoContext,
    }),
  })

  if (!outputProviders || outputProviders?.length < count) {
    for (var i = outputProviders.length; i<count;i++) {
      outputProviders.push(placeholder)
    }
  }

  return (
    <GeoContext.Consumer>
      {(geoContext) => (
        <Grid className={props.className}>
          <Row className={horizontal ? "flex-nowrap":""}>
            {outputProviders?.map((provider, i) => {
              if (!provider) return null;
              const { properties } = provider;
              const backgroundColor =
                properties["background-color"]?.data?.value;
              let totalScoreColor = scoreColors[0].color;
              let totalScorePercentage =
                (provider?.properties?.["total-score"]?.data?.value / 10) * 100;
              const nakedAffiliateLink = provider?.properties?.["affiliate-link"]
                ?.data?.value?.slug
                ? "https://" +
                  site.url +
                  "/goto/" +
                  provider?.properties?.["affiliate-link"]?.data?.value?.slug
                : provider?.properties?.["affiliate-link"]?.data?.value;

              scoreColors.forEach((scoreColor) => {
                if (totalScorePercentage > scoreColor.threshold) {
                  totalScoreColor = scoreColor.color;
                }
              });

              // Link T&C string template if tc-url is set
              const tcUrl = provider?.properties?.["tc-url"]?.data?.value
              if (tcUrl && provider?.["tc-text"] && provider?.["tc-text"]?.indexOf("<a") === -1) {
                provider["tc-text"] = `<a href=${tcUrl} rel="noindex nofollow noopener noreferrer">${provider?.["tc-text"]}</a>`
              } else if (provider?.["tc-text"] && provider?.["tc-text"]?.indexOf("<a href") > -1) {
                provider["tc-text"] = provider["tc-text"]?.replace(/<a href/g, "<a rel='noindex nofollow noopener noreferrer' href")
              }

              return (
                <ProviderContainer
                  className={
                    horizontal
                      ? "col-xs-12 col-md-6 col-lg-4 p-3 p-md-2"
                      : "col-xs-12 p-0"
                  }
                  minimal={minimal}
                  borders={minimal && i===1}
                >
                  <Provider
                    key={"p" + i}
                    color={totalScoreColor}
                    className={"d-flex flex-column " + (minimal ? "p-0" : "p-2")}
                    rtl={site?.properties?.rtl?.data?.value ? 1 : undefined}
                    minimal={minimal}
                    placeholder={provider?.placeholder ? 1:undefined}
                  >
                    {/* showBadges ? <Badge src={badges[i]} alt="Badge" /> : null*/}
                    <Row className="mx-0">
                    <Col xs={3} className="logoContainer px-0 align-items-center justify-content-center placeholderHidden">
                      <LazyLoadComponent
                        afterLoad={() => setLoaded(true)}
                        scrollPosition={scrollPosition}
                      >
                      <a href={getAffiliateLinkWithMetadata({affiliateLink: nakedAffiliateLink, position: `${outboundClickPrefix}${i + 1}`, clickType: "logo", resourceId})}
                        onClick={() => {
                          window._paq.push([
                            "trackEvent",
                            `${outboundClickPrefix}${i + 1}`,
                            provider?.name,
                            "Outclick: Logo",
                          ]);
                        }}
                        target="_blank"
                        rel="noindex nofollow noopener noreferrer"
                        role="img"
                        aria-label={provider?.name}
                        className="outlink d-flex justify-content-center align-items-center"
                        >
                          <Logo
                            src={
                              provider?.placeholder ? provider?.properties?.logotype?.resolved?.[0]
                                .url : (
                              (loaded &&
                                cloudinary.url(
                                  provider?.properties?.logotype?.resolved?.[0]
                                    .url,
                                  { height: 200 }
                                )) ||
                              null)
                            }
                            alt={provider?.name}
                            backgroundcolor={backgroundColor}
                          />
                        </a>
                      </LazyLoadComponent>
                    </Col>
                    <Col
                      xs={9}
                      className="d-flex flex-row flex-md-row row px-1 mx-0"
                    >
                      <Col
                        xs={6}
                        className="content px-1 justify-content-start placeholderHidden"
                      >
                        <span className="providerName mb-2">
                          {provider.name}
                        </span>
                        {disableBonuses ? null:(
                          <span className="bonus">
                            {provider?.["bonus"] || provider?.["bonus-text"]}
                          </span>
                        )}
                      </Col>
                      <Col xs={6} className="px-0 placeholderHidden">
                        <FlagCheckmark countryCode={geoContext?.countryCode} className="mb-2"/>
                        <CtaButton
                          className="outlink d-block glow mb-1"
                          href={getAffiliateLinkWithMetadata({affiliateLink: nakedAffiliateLink, position: `${outboundClickPrefix}${i + 1}`, resourceId})}
                          onClick={() => {
                            window._paq.push([
                              "trackEvent",
                              `${outboundClickPrefix}${i + 1}`,
                              provider?.name,
                              "Outclick: Button",
                            ]);
                          }}
                          target="_blank"
                          rel="noindex nofollow noopener noreferrer"
                        >
                          {site["cta-alternative"] || "Get your bonus"}
                        </CtaButton>
                      </Col>
                    </Col>
                    </Row>

                    <Col xs={12}>
                      {provider["tc-text"] &&
                      provider["tc-text"] !== "undefined" ? (
                        <div
                          className="terms text-left mt-1 placeholderHidden"
                          dangerouslySetInnerHTML={{
                            __html: provider?.["tc-text"],
                          }}
                        />
                      ) : null}
                    </Col>
                  </Provider>
                </ProviderContainer>
              );
            })}
          </Row>
        </Grid>
      )}
    </GeoContext.Consumer>
  );
};
