import React from "react"
import styled from "@emotion/styled"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "../styles/global.css"
import { Grid, Row, Col } from "react-flexbox-grid"
import { formatDate } from "../../utils/index"
import cloudinary from "../../utils/cloudinary"
import { ArrowRight, Chip } from "./styledComponents"

const Post = styled(Row)`
  background: #fff;
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 25px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 20px;

  h2, h3 {
    font-size: 22px;
    line-height: 25px;
  }

  h2 a, h3 a {
    color: #000;
  }

  .thumbnailContainer {
    position: relative;
    /* height: 150px; */

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    padding: 10px;
  }

  .excerpt {
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 13px;

    p, h2, h3, h4 {
      font-size: 13px;
      margin: 0
    }
  }

  div.date {
    color: #fff;
    background: ${props => props.theme.primaryColor};
    display: block;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 10px;
  }


  a.readMore {
    color: #000;
    font-size: 14px;
  }

`
export default props => {
  const { posts = [], scrollPosition, prefetch, count, h2titles = false } = props

  return (
    <Grid>
      {posts.map((post, i) => {
        if (count && i > count - 1) {
          return null
        }

        return (
          <Post key={i}>
            <Col xs={4} md={4} className="thumbnailContainer position-relative">
              {post.properties?.["featured-image"]?.resolved?.[0]?.url ? (
                <LazyLoadImage
                  src={cloudinary.url(post.properties?.["featured-image"]?.resolved?.[0]?.url, { height: 300 })}
                  alt={post.title}
                  scrollPosition={scrollPosition}
                />
              ) : null}

              <div className="date">{post?.properties?.["published-at"]?.data?.value ? formatDate(post?.properties?.["published-at"]?.data?.value) : post.createdAt}</div>

            </Col>
            <Col xs={8} md={8} className="content">
              {h2titles ? (
                <h2 className="mt-0 mb-2">
                  <LinkWrapper href={post.path} key={i} prefetch={prefetch ? 1 : undefined}>
                    {post.title}
                  </LinkWrapper>
                </h2>
              ):(
                <h3 className="mt-0 mb-2">
                  <LinkWrapper href={post.path} key={i} prefetch={prefetch ? 1 : undefined}>
                    {post.title}
                  </LinkWrapper>
                </h3>
              )}

              <div className="d-flex align-items-center" style={{justifyContent:"space-between"}}>
                {post?._category?.name ? <Chip href={post?._category?.path}>{post?._category?.name}</Chip>:null}
                <a href={post.path} className="readMore">Read more <ArrowRight className="ml-1" /></a>
              </div>
            </Col>
          </Post>
        )
      })}
    </Grid>
  )
}

const LinkWrapper = props => {
  const { href } = props
  return <a href={href}>{props.children}</a>
}
