import React from "react"
import Layout from "../../components/layout"
import styled from "@emotion/styled"
import Helmet from "react-helmet"
import { trackWindowScroll } from "react-lazy-load-image-component"
import { Grid, Row, Col } from "react-flexbox-grid"
import { HeroSection, StyledSection, MarkdownContent, Chip } from "../../components/styledComponents"
import ProviderListSmall from "../../components/providerListSmall"
import Breadcrumbs from "../../components/breadcrumbs"
import PostList from "../../components/postList"
import { formatDate } from "../../../utils/index"
import cloudinary from "../../../utils/cloudinary";
import snarkdown from 'snarkdown'

const Date = styled.p`
  font-size: 14px;
  margin: 10px 0;
  color: #999;
  white-space: nowrap;
`

const FeaturedImage = styled.img`
  width:100%;
  border-radius:5px;
`

const SectionLink = styled.a`
  background: ${props => props.theme.secondaryColor};
  border-radius: ${props => props.theme.borderRadius};
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
`

const AuthorBox = styled.div`
  background: #fff;
  border-radius: ${props => props.theme.borderRadius};

  .authorLabel {
    text-transform: uppercase;
    font-size: 10px;
    display: block;
  }

  .authorName {
    display: block;
    font-weight: 600;
  }

  .authorBio {
    color: #555;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
  }
`

function jsonSchema(props) {
  const { pageContext, publishedDate } = props;
  const { post, site, themeSettings } = pageContext;
  const siteUrl = site?.url?.indexOf("https://") === 0 ? site?.url : "https://" + site?.url
  
  let schema = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": siteUrl + post.path
    },
    "headline": post?.title,
    "datePublished": publishedDate,
    "dateModified": publishedDate,
    "author": {
      "@type": "Organization",
      "name": site?.properties?.name?.data?.value,
    },
    "publisher": {
      "@type": "Organization",
      "name": site?.properties?.name?.data?.value,
      "logo": {
        "@type": "ImageObject",
        "url": themeSettings["logotype"] ? themeSettings["logotype"] : site?.properties?.logotype?.resolved?.[0]?.url
      }
    },
    "description": post?.title
  };

  if (post?.properties?.["featured-image"]?.resolved?.[0]?.url) {
    schema["image"] = [
      post?.properties?.["featured-image"]?.resolved?.[0]?.url
    ]
  }

  return schema
}

const Post = props => {
  const { pageContext, scrollPosition } = props
  const { post = {}, modules = {}, site } = pageContext
  const { featuredProviders: providers, postList = [], postTags, breadcrumbs = [] } = modules
  const publishedDate = post?.properties?.["published-at"]?.data?.value ? formatDate(post?.properties?.["published-at"]?.data?.value) : post.createdAt
  const section = breadcrumbs?.[breadcrumbs?.length - 2]
  const contentTop = post?.content?.substring(0, post?.content?.indexOf("</p>") + 4)
  const contentBottom = post?.content?.substring(post?.content?.indexOf("</p>") + 4, post?.content?.length)
  const authorName = post?.properties?.author?.resolved?.[0]?.properties?.find(p => p.key === "name")?.data?.value
  const authorAvatar = post?.properties?.author?.resolved?.[0]?.properties?.find(p => p.key === "profile-picture")?.resolved?.[0]?.url
  const authorBio = post?.properties?.author?.resolved?.[0]?.content?.find(p => p.key === "content")?.strings?.[0]?.data

  return (
    <Layout
      {...props}
      headerBackground={"#fff"}
      footerLight
      htmlTitle={post["html-title"] || post.title + " | " + site?.name}
      metaDescription={post["meta-description"] || post["title"] || ""}
      image={post?.properties?.["featured-image"]?.resolved?.[0]?.url}
      path={post.path}
      deepLinks={true}
      scrollPosition={scrollPosition}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(jsonSchema({...props, publishedDate}))}
        </script>
      </Helmet>
      <HeroSection hero pattern={1}>
        <Grid>
          <Breadcrumbs {...props} invert={1} />
        </Grid>
      </HeroSection>
      <StyledSection className="pt-5">
        <Grid>
          <Row>
            <Col xs={12} md={8} mdOffset={0} className="pr-md-4">
              <h1 className="my-0">{post.title}</h1>

              <div className="d-flex align-items-center my-4">
                {section ? <SectionLink href={section?.url} className="section mr-4">{section?.title}</SectionLink> : null}
                <svg width="12px" height="14px" viewBox="0 0 12 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-225.000000, -292.000000)">
                            <rect fill="#FFFFFF" x="0" y="0" width="1440" height="3491"></rect>
                            <rect fill="#F1F1FA" x="0" y="144" width="1440" height="2526"></rect>
                            <g transform="translate(97.000000, 176.000000)" fill="#81858B" opacity="0.5">
                                <g transform="translate(126.000000, 116.000000)">
                                    <path d="M10.6666667,6.66666667 L5.33333333,6.66666667 C4.96666667,6.66666667 4.66666667,6.96666667 4.66666667,7.33333333 C4.66666667,7.7 4.96666667,8 5.33333333,8 L10.6666667,8 C11.0333333,8 11.3333333,7.7 11.3333333,7.33333333 C11.3333333,6.96666667 11.0333333,6.66666667 10.6666667,6.66666667 Z M12.6666667,2 L12,2 L12,1.33333333 C12,0.966666667 11.7,0.666666667 11.3333333,0.666666667 C10.9666667,0.666666667 10.6666667,0.966666667 10.6666667,1.33333333 L10.6666667,2 L5.33333333,2 L5.33333333,1.33333333 C5.33333333,0.966666667 5.03333333,0.666666667 4.66666667,0.666666667 C4.3,0.666666667 4,0.966666667 4,1.33333333 L4,2 L3.33333333,2 C2.59333333,2 2,2.6 2,3.33333333 L2,12.6666667 C2,13.4 2.59333333,14 3.33333333,14 L12.6666667,14 C13.4,14 14,13.4 14,12.6666667 L14,3.33333333 C14,2.6 13.4,2 12.6666667,2 Z M12,12.6666667 L4,12.6666667 C3.63333333,12.6666667 3.33333333,12.3666667 3.33333333,12 L3.33333333,5.33333333 L12.6666667,5.33333333 L12.6666667,12 C12.6666667,12.3666667 12.3666667,12.6666667 12,12.6666667 Z M8.66666667,9.33333333 L5.33333333,9.33333333 C4.96666667,9.33333333 4.66666667,9.63333333 4.66666667,10 C4.66666667,10.3666667 4.96666667,10.6666667 5.33333333,10.6666667 L8.66666667,10.6666667 C9.03333333,10.6666667 9.33333333,10.3666667 9.33333333,10 C9.33333333,9.63333333 9.03333333,9.33333333 8.66666667,9.33333333 Z" id="calendar"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <Date className="ml-2 mr-4">{publishedDate}</Date>

                {authorName ? (<>
                  <svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g transform="translate(-333.000000, -294.000000)">
                              <rect fill="#FFFFFF" x="0" y="0" width="1440" height="3491"></rect>
                              <rect fill="#F1F1FA" x="0" y="144" width="1440" height="2526"></rect>
                              <g transform="translate(97.000000, 176.000000)" fill="#81858B" opacity="0.5">
                                  <g transform="translate(235.500000, 117.000000)">
                                      <path d="M8,1.33333333 C4.32,1.33333333 1.33333333,4.32 1.33333333,8 C1.33333333,11.68 4.32,14.6666667 8,14.6666667 C11.68,14.6666667 14.6666667,11.68 14.6666667,8 C14.6666667,4.32 11.68,1.33333333 8,1.33333333 Z M8,3.33333333 C9.10666667,3.33333333 10,4.22666667 10,5.33333333 C10,6.44 9.10666667,7.33333333 8,7.33333333 C6.89333333,7.33333333 6,6.44 6,5.33333333 C6,4.22666667 6.89333333,3.33333333 8,3.33333333 Z M8,12.8 C6.33333333,12.8 4.86,11.9466667 4,10.6533333 C4.02,9.32666667 6.66666667,8.6 8,8.6 C9.32666667,8.6 11.98,9.32666667 12,10.6533333 C11.14,11.9466667 9.66666667,12.8 8,12.8 Z" id="profile"></path>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                 <Date className="ml-2">{authorName}</Date>
                 </>) : null}
              </div>

              <MarkdownContent
                dangerouslySetInnerHTML={{ __html: contentTop }}
              />

              {post?.properties?.["featured-image"]?.resolved?.[0]?.url ? (
                <FeaturedImage
                  src={
                    cloudinary.url(post?.properties?.["featured-image"]?.resolved?.[0]?.url, { width: 892, height: 468 })
                  }
                  alt={post.title}
                  className="mb-4"
                />
              ) : null}


              <MarkdownContent
                dangerouslySetInnerHTML={{ __html: contentBottom }}
              />

              {postTags?.length ? (<div className="mt-3 pt-3 d-block" style={{
                borderTop: "1px solid #dfdfdf"
              }}>
                {postTags?.map(tag => {
                  return <Chip href={tag?.path} large className="mr-2 mb-2">{tag?.name}</Chip>
                })}
              </div>) : null}

              {authorName ? (
                <AuthorBox className="d-flex flex-column p-3 mt-5 mb-3">
                  <div className="d-flex flex-row align-items-center">
                    {authorAvatar ? <img src={authorAvatar} className="mr-2" alt="Avatar"/> : null}
                    <div className="d-flex flex-column justify-content-center">
                      <span className="authorLabel mb-1">AUTHOR</span>
                      <span className="authorName">{authorName}</span>
                    </div>
                  </div>

                  {authorBio ? (
                    <>
                      <div style={{height: "1px", display: "block", background:"#dfdfdf"}} className="my-4"/>

                      <div>
                        <p className="authorBio mt-0" dangerouslySetInnerHTML={{
                          __html: authorBio ? snarkdown(authorBio) : "",
                        }} />
                      </div>
                    </>
                  ):null}
                </AuthorBox>
              ):null}
            </Col>

            <Col xs={0} md={4} mdOffset={0} className="d-block d-md-block my-0">
              <h3>{site?.["casino-promo"] || "Casino promo"}</h3>
              <div className="d-block d-md-block">
                <ProviderListSmall
                  {...props}
                  providers={providers}
                  count={3}
                  dynamic
                  showBadges={false}
                  outboundClickPrefix="post_"
                  resourceId={post?.allanId}
                />
              </div>

              <h3 className="mt-4">{site?.["featured-news-heading"]}</h3>
              <PostList
                posts={postList}
                scrollPosition={scrollPosition}
                prefetch={false}
                md={12}
                className="mt-5"
                {...props}
              />

            </Col>
          </Row>
        </Grid>
      </StyledSection>
    </Layout>
  )
}

export default trackWindowScroll(Post)
